//console.log("ENV for debugging purposes...");

export const apiBaseURL =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://us.api.storyrabbit.ai/"
    : "https://api.storyrabbit.ai/";

export const apiEndpoints = {
  PERSONA: "/admin/persona",
  PERSONA_IMAGE: "/admin/persona-image",
  VOICES: "/admin/voices",
  TEST_PODCAST: "/admin/test-podcast",
  REPORTS: "/admin/report",
  PERSONA_ORDER: "/admin/persona-order",
};

export const mapsApiKey = process.env.REACT_APP_MAPS_API_KEY || "";
