import { apiEndpoints, mapsApiKey } from "../constants";
import { PersonaModel } from "../model/persona";
import { PlaceDetail, PlacesResponse } from "../model/poi";
import { TestPodcastPayload } from "../model/test-podcast-payload";
import { TestPodcastResponseModel } from "../model/test-podcast-response-model";
import { UploadResponseModel } from "../model/upload-response";
import { PersonaVoice } from "../model/voice";
import { apiDelete, apiPost, apiPut, useGet } from "./method";
import { ReportModel } from "../model/report";

// Get all personas
export const useApiPersonas = () =>
  useGet<Array<PersonaModel>>(apiEndpoints.PERSONA);

// Get single persona
export const useApiPersona = (id: string) =>
  useGet<PersonaModel>(apiEndpoints.PERSONA + "/" + id);

// Create persona
export const apiCreatePersona = async (persona: PersonaModel) => {
  return await apiPost<PersonaModel>(apiEndpoints.PERSONA, persona);
};

// Update personas order
export const apiReorderPersonas = async (order: any) => {
  return await apiPost<any>(apiEndpoints.PERSONA_ORDER, order);
};

// Clone persona
export const apiClonePersona = async (personaId: number) => {
  return await apiPost<PersonaModel>(
    apiEndpoints.PERSONA + "/" + personaId.toString() + "/clone",
    {}
  );
};

// Delete persona
export const apiDeletePersona = async (personaId: number) => {
  return await apiDelete<any>(
    apiEndpoints.PERSONA + "/" + personaId.toString()
  );
};

// Update persona
export const apiUpdatePersona = async (persona: PersonaModel) => {
  return await apiPut<PersonaModel>(
    apiEndpoints.PERSONA + "/" + persona.id.toString(),
    persona,
    null
  );
};

// Clone persona
export const apiUploadPersonaImage = async (filename: string) => {
  return await apiPost<UploadResponseModel>(apiEndpoints.PERSONA_IMAGE, {
    filename: filename,
  });
};

// Generate test podcast
export const apiGenerateTestStory = async (payload: TestPodcastPayload) => {
  return await apiPost<TestPodcastResponseModel>(
    apiEndpoints.TEST_PODCAST,
    payload
  );
};

// Upload file to url
export const apiUploadFile = async (uploadUrl: string, data: Blob) => {
  return await apiPut<any>(uploadUrl, data, {
    "Content-Type": data.type,
  });
};

// Get voices
export const useApiVoices = () =>
  useGet<Array<PersonaVoice>>(apiEndpoints.VOICES);

// Get POIs
export const apiGetPois = async (lat: number, lon: number, count: number) => {
  var resultPOIs: PlaceDetail[] = [];
  try {
    const searchRadiusMeters = [50, 100, 200, 500, 1000];
    const fieldMasks = [
      "places.name",
      "places.displayName",
      "places.formattedAddress",
      "places.address_components",
      "places.editorialSummary",
      "places.location",
      "places.photos",
      "places.types",
    ];
    const fieldMaskString = fieldMasks.join(",");
    for (const radius of searchRadiusMeters) {
      if (resultPOIs.length >= count) {
        // ignore: avoid_print
        console.log("Place polling reached target count at radius " + radius);
        break;
      }
      const requestData = {
        includedTypes: [
          "airport",
          "tourist_attraction",
          "amusement_park",
          "aquarium",
          "art_gallery",
          "bar",
          "book_store",
          "bowling_alley",
          "campground",
          "casino",
          "cemetery",
          "church",
          "city_hall",
          "courthouse",
          "embassy",
          "fire_station",
          "hindu_temple",
          "locality",
          "library",
          "movie_theater",
          "museum",
          "painter",
          "park",
          "school",
        ],
        maxResultCount: count,
        locationRestriction: {
          circle: {
            center: {
              latitude: lat,
              longitude: lon,
            },
            radius: radius,
          },
        },
        rankPreference: "POPULARITY",
      };
      const response = await fetch(
        "https://places.googleapis.com/v1/places:searchNearby",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": mapsApiKey,
            "X-Goog-FieldMask": fieldMaskString,
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        const data: PlacesResponse = await response.json();
        if (data.places && data.places.length > 0)
          for (const place of data.places) {
            if (resultPOIs.length >= count) break;
            if (!resultPOIs.find((it) => it.name === place.name))
              resultPOIs.push(place);
          }
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return resultPOIs;
};

// Get Reports
export const useApiReports = () =>
  useGet<Array<ReportModel>>(apiEndpoints.REPORTS);
