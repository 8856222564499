import HeaderBar from "../components/layout/Headerbar";
import NavBar from "../components/layout/NavBar";
import actions from "../assets/actions.png";
import { useApiReports } from "../api/api";

function ReportsPage() {
    const reports = useApiReports();

    return (
        <div className="app">
            <HeaderBar>
                <img src={actions} alt="Reports" height={32} style={{ display: "inline-block", marginLeft: 20, marginTop: 25 }} />
                <h1
                    style={{
                        display: "inline-block",
                        marginLeft: 8,
                        verticalAlign: "top",
                        paddingTop: 13.5,
                        fontWeight: 600,
                    }}
                >
                    Reports
                </h1>
                <div
                    style={{
                        display: "inline-flex",
                        right: 0,
                        position: "absolute",
                        boxSizing: "border-box",
                        padding: 15,
                    }}
                ></div>
            </HeaderBar>
            <NavBar selectedIndex={1} />
            <div className="content" style={{ paddingLeft: 16, paddingRight: 16 }}>
                {(reports.data || []).map((report) => (
                    <div key={report.id} style={{ marginBottom: 16 }}>
                        <h2 style={{textTransform: "capitalize"}}>{report.status}: {(new Date(report.createdAt)).toLocaleString()}</h2>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                Entity: {report.entityType} (ID: {report.entityId})
                            </div>
                            <div>Reason: {report.reason}</div>
                            <div>User: {report.userProfile.username}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default ReportsPage;
